import React, { useState, useRef, useEffect } from 'react'; 
import { storage } from '../firebaseConfig'; // Import Firebase Storage configuration
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject,listAll } from "firebase/storage"; // Import Firebase Storage functions
import loadingGif from '../Assets/loading.gif';
import QuiltBuilderWithLayout from './QuiltBuilderWithLayout';
import { analytics,db } from '../firebaseConfig';
import { getAnalytics, logEvent } from "firebase/analytics";
import { collection, addDoc,onSnapshot,query, orderBy,deleteDoc } from 'firebase/firestore';
import QuiltEditor from './QuiltEditor'; // Import QuiltEditor component
import PaymentGateway from './PaymentGateway';
import { doc, getDocs,setDoc, getDoc } from "firebase/firestore"; // Firestore functions
import { useAuth } from '../components/AuthContext';
import { updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import logoSrc from '../Assets/favicon-32x32.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faDownload, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import AILayouts from './AILayouts';
import QuilterOptions from './QuilterOptions';
import ShareModal from './ShareModal';
import FeedbackSection from './FeedbackSection';
import { drawOnCanvas } from './canvasUtils';
import { handleDownloadClick, handleShareClick, confirmReset } from "./quiltActions";
import priceMapping from './priceMapping';
import { getAuth } from 'firebase/auth';
import TawkChat from './TawkChat';
import HotjarIntegration from './HotjarIntegration'

// Define whitelist emails as a constant outside the component to ensure it's not recreated on rerender
const WHITELISTED_EMAILS = [
    //"gptonly522@gmail.com",
    "beautgroup@gmail.com", 
    "hari765@gmail.com", 
    "lakshman52264@gmail.com"
];

function QuiltBuilder() {
    const [uploadedImages, setUploadedImages] = useState([]);
    const [gridSize, setGridSize] = useState(''); // Default no selection for grid size
    const [generated, setGenerated] = useState(false); // Flag to indicate when to generate quilt layout
    const [requiredImages, setRequiredImages] = useState(0); // Number of images required
    const [showWarning, setShowWarning] = useState(''); // State for controlling the warning visibility
    const fileInputRef = useRef(null); // For accessing the file input
    const [quiltImageUrl, setQuiltImageUrl] = useState('');  // New state to hold the quilt image URL
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false); // New state for loading
    const [previousLayoutType, setPreviousLayoutType] = useState(''); // Track the previous layout type
    // Add this state to store the existing session ID
    const [existingSessionId, setExistingSessionId] = useState(null);
    const quiltDisplayRef = useRef(null); // Reference for quilt display section
    const [rating, setRating] = useState(0); // User rating
    const [feedback, setFeedback] = useState(''); // User feedback
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const canvasRef = useRef(null); // Canvas reference
    const [isEditing, setIsEditing] = useState(false); // Toggle for edit mode
    const [quiltGrid, setQuiltGrid] = useState([]); // Grid layout for quilt tiles
    const [sessionId, setSessionId] = useState(null);
    const [isEditUnlocked, setIsEditUnlocked] = useState(false);
    const [generateDisabled, setGenerateDisabled] = useState(false);
    const { currentUser } = useAuth(); // Get currentUser from context
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [isResetLoading, setIsResetLoading] = useState(false);
    const [originalQuiltGrid, setOriginalQuiltGrid] = useState([]); // Original grid layout for revert
    const [isProcessingImages, setIsProcessingImages] = useState(false);
    const [processingError, setProcessingError] = useState(null);
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);
    const [shareLink, setShareLink] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [aiSuggestions, setAISuggestions] = useState(null);
    const [loadingAI, setLoadingAI] = useState(false);
    const [aiError, setAIError] = useState(null);
    // Function to toggle the reset modal
    const openResetModal = () => setIsResetModalOpen(true);
    const closeResetModal = () => setIsResetModalOpen(false);
    const [aiGeneratedLayouts, setAIGeneratedLayouts] = useState([]); // Stores AI layouts

    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false); // Payment modal visibility
    const [isPaymentCompleted, setIsPaymentCompleted] = useState(false); // Payment status
    const [userCountry, setUserCountry] = useState(null); // Detected country
    const [priceInRupees, setPriceInRupees] = useState(null); // Price in INR
    const [isPremiumUser, setIsPremiumUser] = useState(false);
    



// Helper function to convert matrix to Firestore-compatible format
const convertMatrixToFirestore = (matrix) => {
    // Convert nested arrays to an object with numbered keys
    return matrix.reduce((acc, row, rowIndex) => {
        row.forEach((cell, colIndex) => {
            acc[`${rowIndex}_${colIndex}`] = cell;
        });
        return acc;
    }, {});
};

// Helper function to convert Firestore format back to matrix
const convertFirestoreToMatrix = (matrixObject, gridSize) => {
    // Parse rows and columns from gridSize string (e.g., "4x6")
    const [columns, rows] = gridSize.split('x').map(Number);
    
    // Create a 2D array with the correct dimensions
    const matrix = Array(rows).fill().map(() => Array(columns).fill(''));
    
    // Fill in the values from the Firestore object
    Object.entries(matrixObject).forEach(([key, value]) => {
        const [row, col] = key.split('_').map(Number);
        // Check if indices are within bounds
        if (row < rows && col < columns) {
            matrix[row][col] = value;
        }
    });
    
    return matrix;
};

// Update saveSessionData to convert matrix format
const saveSessionData = async () => {
    if (!currentUser || !currentUser.uid) return;

    const sessionRef = doc(db, 'sessions', currentUser.uid);

    // Convert AI layouts to Firestore-compatible format
    const firestoreCompatibleLayouts = aiGeneratedLayouts.map(layout => ({
        layout_name: layout.layout_name,
        description: layout.description,
        matrix: convertMatrixToFirestore(layout.matrix)
    }));

    const sessionData = {
        uploadedImages,
        gridSize,
        quiltImageUrl,
        quiltGrid,
        generated,
        aiGeneratedLayouts: firestoreCompatibleLayouts
    };

    try {
        await setDoc(sessionRef, sessionData, { merge: true });
        console.log("Session data saved to Firestore with AI layouts.");
    } catch (error) {
        console.error("Error saving session data to Firestore:", error);
    }
};

// Update restoreSessionData to handle matrix conversion correctly
const restoreSessionData = async () => {
    if (!currentUser || !currentUser.uid) return;

    const sessionRef = doc(db, 'sessions', currentUser.uid);
    
    try {
        const sessionSnap = await getDoc(sessionRef);

        if (sessionSnap.exists()) {
            const sessionData = sessionSnap.data();
            console.log("Restoring session data from Firestore:", sessionData);

            // Restore basic session data
            setGridSize(sessionData.gridSize || '4x4'); // Provide default grid size
            setUploadedImages(sessionData.uploadedImages || []);
            setQuiltImageUrl(sessionData.quiltImageUrl || '');
            setQuiltGrid(sessionData.quiltGrid || []);
            setGenerated(sessionData.generated || false);
            setFeedbackSubmitted(sessionData.feedbackSubmitted || false);
            
            // Restore and convert AI layouts if they exist
            if (sessionData.aiGeneratedLayouts && sessionData.gridSize) {
                const convertedLayouts = sessionData.aiGeneratedLayouts.map(layout => ({
                    layout_name: layout.layout_name,
                    description: layout.description,
                    matrix: convertFirestoreToMatrix(layout.matrix, sessionData.gridSize)
                }));
                setAIGeneratedLayouts(convertedLayouts);
            }
        }
    } catch (error) {
        console.error("Error restoring session data:", error);
    }
};
// Restore session data on component mount
useEffect(() => {
    restoreSessionData();
}, [currentUser]);

// Restore saved state from session storage
useEffect(() => {
    const savedState = JSON.parse(sessionStorage.getItem("quiltState"));
    if (savedState) {
        console.log("Restoring state from sessionStorage:", savedState);

        setGridSize(savedState.gridSize || '');
        setUploadedImages(savedState.uploadedImages || []);
        setQuiltImageUrl(savedState.quiltImageUrl || '');
        setQuiltGrid(savedState.quiltGrid || []);
        setIsEditUnlocked(savedState.isEditUnlocked || false);
        setGenerateDisabled(savedState.generateDisabled || false);
        
        // Restore AI layouts from session storage
        if (savedState.aiGeneratedLayouts) {
            setAIGeneratedLayouts(savedState.aiGeneratedLayouts);
        }

        console.log("isEditUnlocked after restoration:", savedState.isEditUnlocked);
        sessionStorage.removeItem("quiltState");
    }
}, []);
useEffect(() => {
    const checkInitialPaymentStatus = async () => {
        if (!currentUser) return;

        try {
            const paymentsCollectionRef = collection(db, `customers/${currentUser.uid}/payments`);
            const querySnapshot = await getDocs(paymentsCollectionRef);

            if (!querySnapshot.empty) {
                const paymentDoc = querySnapshot.docs[0];
                const paymentData = paymentDoc.data();
                setIsPremiumUser(paymentData.status === "succeeded");
            }
        } catch (error) {
            console.error("Error checking initial payment status:", error);
        }
    };

    checkInitialPaymentStatus();
}, [currentUser]);

// Save session data whenever relevant states change
useEffect(() => {
    if (generated) {
        saveSessionData();
    }
}, [uploadedImages, gridSize, quiltGrid, aiGeneratedLayouts]);

// Set session ID when user is available
useEffect(() => {
    if (currentUser && !sessionId) {
        setSessionId(currentUser.uid);
        setExistingSessionId(currentUser.uid); // Synchronize with existingSessionId
    }
}, [currentUser, sessionId]);

// Scroll to quilt image when it's available
useEffect(() => {
    if (quiltImageUrl && !loading) {
        setTimeout(() => {
            scrollToQuilt();
        }, 100); // Short delay to ensure rendering is complete
    }
}, [quiltImageUrl, loading]);

// Scroll function
const scrollToQuilt = () => {
    if (quiltDisplayRef.current) {
        const offset = -50; // Adjust offset if needed
        const topPosition = quiltDisplayRef.current.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
};

// Scroll down during loading
useEffect(() => {
    if (loading) {
        scrollToQuilt();
    }
}, [loading]);

// Scroll to top when component loads
useEffect(() => {
    window.scrollTo(0, 0);
}, []);

// Update canvas whenever the quilt grid changes
useEffect(() => {
    console.log("useEffect triggered: quiltGrid =", quiltGrid);
    if (quiltGrid.length > 0) {
        drawOnCanvas(canvasRef, quiltGrid, gridSize, logoSrc);
    }
}, [quiltGrid, canvasRef, gridSize, logoSrc]);




    const checkTileUploadStatus = async () => {
        const sessionId = currentUser.uid;
        const sessionRef = doc(db, 'sessions', sessionId);
        const sessionDoc = await getDoc(sessionRef);
    
        if (sessionDoc.exists()) {
            const sessionData = sessionDoc.data();
            const totalTilesUploaded = sessionData?.total_tiles_uploaded || 0;
            return totalTilesUploaded >= requiredImages;
        }
        return false;
    };
    
    const waitForAllTilesToUpload = async () => {
        const maxRetries = 10;
        let retries = 0;
        while (retries < maxRetries) {
            const allTilesUploaded = await checkTileUploadStatus();
            if (allTilesUploaded) return true;
            await new Promise((resolve) => setTimeout(resolve, 6000)); 
            retries += 1;
        }
        return false;
    };
    




        // Function to toggle edit mode
        const handleEditClick = () => {
            setOriginalQuiltGrid([...quiltGrid]);
            setIsEditing(true); // Enter edit mode
        };
    
    const handleSaveChanges = (updatedGrid) => {
        console.log("Saving changes with updatedGrid:", updatedGrid);
        setQuiltGrid(updatedGrid);
        setIsEditing(false);
    
        setTimeout(() => {
            drawOnCanvas(updatedGrid);
        }, 0);
    };

    const handleCancelEdit = () => {
        setQuiltGrid([...originalQuiltGrid]); // Revert to original layout
        setIsEditing(false); // Exit edit mode without saving
    };


    // Function to calculate required images based on grid size
    const calculateRequiredImages = (gridSize) => {
        switch (gridSize) {

            case "4x4":
                return 16;
            case "4x5":
                return 20;
            case "4x6":
                return 24;
            case "4x7":
                return 28;
            case "5x5":
                return 25;
            case "5x6":
                return 30;
            case "5x7":
                return 35;
            case "6x6":
                return 36;
            case "6x7":
                return 42;
            case "7x7":
                return 49;
            case "7x8":
                return 56;
            case "8x8":
                return 64;
            default:
                return 0; // Fallback for cases where no grid is selected
        }
    };
  
    const handleImageUpload = async (event) => {
        setIsProcessingImages(true); // Start processing state
        setProcessingError(null); // Clear previous errors
        const files = Array.from(event.target.files);
    
        if (uploadedImages.length + files.length > requiredImages) {
            setShowWarning(`You can only upload ${requiredImages} T-shirts for the ${gridSize} grid.`);
            setIsProcessingImages(false);
            return;
        }
    
        if (!sessionId) {
            setSessionId(currentUser.uid); // Generate sessionId if not set
        }
    
        const newUploadedImages = await Promise.all(
            files.map(async (file) => {
                try {
                    // Upload to Firebase and get URL
                    const uniqueFileName = `${uuidv4()}_${file.name}`;
                    const storageRef = ref(storage, `quilt_designs/${sessionId}/${uniqueFileName}`);
                    await uploadBytes(storageRef, file);
                    const tshirtUrl = await getDownloadURL(storageRef);
    
                    // Immediately add the image to the displayed list with `processed: false`
                    const uploadedFile = { url: tshirtUrl, path: storageRef.fullPath, processed: false };
                    setUploadedImages((prevImages) => [...prevImages, uploadedFile]);
    
                    // Send the image to the backend for processing
                    const formData = new FormData();
                    formData.append('session_id', sessionId);
                    formData.append('tshirt_url', tshirtUrl);
                    formData.append('image', file);
    
                    const response = await fetch('https://my-fastapi-app-289455571528.us-central1.run.app/process_image', {
                        method: 'POST',
                        body: formData,
                    });
    
                    const result = await response.json();
                    if (response.ok) {
                        console.log(`Processed image ${file.name} successfully.`);
                        // Update the uploaded image's `processed` status in state
                        setUploadedImages((prevImages) =>
                            prevImages.map((img) =>
                                img.url === tshirtUrl ? { ...img, processed: true } : img
                            )
                        );
                    } else {
                        console.error(`Failed to process image ${file.name}:`, result.error);
                        setProcessingError(`Failed to process image ${file.name}`);
                    }
                    return uploadedFile;
                } catch (error) {
                    console.error(`Error during processing for ${file.name}:`, error);
                    setProcessingError(`Error processing image ${file.name}`);
                    return null;
                }
            })
        );
    
        setIsProcessingImages(false); // End processing state after upload completion
    };
    

    // Handle grid size change
    const handleGridSizeChange = (event) => {
        if (generated) {
            // Prevent grid size change after quilt generation and prompt user to reset
            setShowWarning('Grid size cannot be changed after quilt generation. Please reset to change the grid size.');
            return; // Prevent further execution if quilt is already generated
        }

        const selectedGridSize = event.target.value;
        setGridSize(selectedGridSize);
        const calculatedImages = calculateRequiredImages(selectedGridSize);
        setRequiredImages(calculatedImages);

        logEvent(analytics, 'select_grid_size', { grid_size: selectedGridSize });

        // Allow user to upload more images if new grid size requires more
        if (uploadedImages.length < calculatedImages) {
            setShowWarning(`Please upload ${calculatedImages - uploadedImages.length} more T-shirts for the ${selectedGridSize} grid.`);
        } else if (uploadedImages.length === calculatedImages) {
            setShowWarning(''); // No warning if the exact number of images is already uploaded
        } else {
            setShowWarning(`You have uploaded more than required. You can only upload ${calculatedImages} T-shirts for the ${selectedGridSize} grid.`);
        }

        setGenerated(false); // Reset the generation flag if the grid size is changed
    };


    // Handle image upload click
    const handleUploadClick = (e) => {
        if (!gridSize ) {
            e.preventDefault(); // Prevent the file picker from opening
            setShowWarning('Please select grid size before uploading images.');
        } else if (uploadedImages.length >= requiredImages) {
            e.preventDefault(); // Prevent file picker if user has uploaded the maximum number of images
            setShowWarning(`You can only upload ${requiredImages} T-shirts for the ${gridSize} grid.`);
        } else {
            setShowWarning(''); // Hide warning if everything is selected
        }
    };

    const handleGenerateClick = async () => {
        if (uploadedImages.length < requiredImages) {
            setShowWarning(`Please upload exactly ${requiredImages} T-shirts for the ${gridSize} grid before generating.`);
        } else if (!gridSize) {
            setShowWarning('Please select grid size before generating.');
        } else if (isProcessingImages || processingError) {
            setShowWarning(processingError ? processingError : 'Processing images, please wait...');
        } else {
            setGenerated(true);
            setLoading(true); // Start loading state
            setShowWarning('');
            setGenerateDisabled(true); // Disable Generate button
        
            try {
                // Wait for all tiles to be uploaded before generating the quilt
                const allTilesUploaded = await waitForAllTilesToUpload();
                if (!allTilesUploaded) {
                    setShowWarning("Not all images have been processed. Please try again.");
                    setLoading(false);
                    //setGenerateDisabled(false);
                    return;
                }
        
                const payload = {
                    session_id: currentUser.uid,  // Use the userId as sessionId
                    grid_size: gridSize,
                    layout_type: "Random",
                };
        
                const apiRequest = fetch('https://my-fastapi-app-289455571528.us-central1.run.app/generate_quilt', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                }).then(response => response.json());
        
                const delay = new Promise(resolve => setTimeout(resolve, 3000));
                const [result] = await Promise.all([apiRequest, delay]);
        
                if (result && result.quilt_image_url) {
                    console.log('Quilt generated successfully:', result);
                    setQuiltImageUrl(result.quilt_image_url);
                    setQuiltGrid(result.tile_urls);
                    //await saveSessionData();
                } else {
                    const errorMessage = result.error || 'An unexpected error occurred while generating the quilt. Please reset and try again.';
                    console.error('Failed to generate quilt:', errorMessage);
                    setShowWarning(`Error generating quilt: ${errorMessage}`);
                }
            } catch (error) {
                console.error('Error during image upload or API request:', error);
                setShowWarning(`Error: ${error.message}`);
            } finally {
                setLoading(false); // End loading state after at least 3 seconds
            }
        }
    };

    const handleDeleteImage = async (index) => {
        const imageToDelete = uploadedImages[index];
    
        if (!imageToDelete) {
            console.warn("Image not found at the specified index.");
            return;
        }
    
        try {
            // Step 1: Access Firestore to find and delete associated tile
            if (currentUser && currentUser.uid) {
                const sessionRef = doc(db, 'sessions', currentUser.uid);
                const sessionSnap = await getDoc(sessionRef);
    
                if (sessionSnap.exists()) {
                    const sessionData = sessionSnap.data();
    
                    // Find the tile associated with this T-shirt image using `tshirt_url`
                    const tileToDelete = sessionData.tile_list.find(
                        (tile) => tile.tshirt_url === imageToDelete.url
                    );
    
                    if (tileToDelete) {
                        // Step 2: Update Firestore by removing the tile from `tile_list`
                        const updatedTileList = sessionData.tile_list.filter(
                            (tile) => tile.url !== tileToDelete.url
                        );
    
                        await updateDoc(sessionRef, {
                            tile_list: updatedTileList,
                            total_tiles_uploaded: updatedTileList.length,
                        });
                        console.log("Updated Firestore document successfully.");
    
                        // Step 3: Update local state immediately
                        setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
                        console.log("T-shirt image removed from local state.");
    
                        // Step 4: Delete the associated tile from Firebase Storage
                        const tilePath = tileToDelete.url.split(".appspot.com/")[1];
                        const decodedTilePath = decodeURIComponent(tilePath);
                        console.log("Decoded tile path:", decodedTilePath);
    
                        try {
                            const tileRef = ref(storage, decodedTilePath);
                            await deleteObject(tileRef);
                            console.log(`Deleted associated tile from Firebase Storage: ${decodedTilePath}`);
                        } catch (error) {
                            if (error.code === 'storage/object-not-found') {
                                console.warn(`Tile not found in Firebase Storage: ${decodedTilePath}`);
                            } else {
                                throw error;
                            }
                        }
                    } else {
                        console.warn("No associated tile found for the deleted T-shirt.");
                    }
                } else {
                    console.warn("No session data found in Firestore for this user.");
                }
            }
    
            // Step 5: Delete the T-shirt image from Firebase Storage
            if (imageToDelete.path) {
                const storageRef = ref(storage, imageToDelete.path);
                await deleteObject(storageRef);
                console.log(`Successfully deleted T-shirt image from Firebase Storage: ${imageToDelete.path}`);
            }
        } catch (error) {
            console.error("Error deleting T-shirt image and tile:", error);
        }
    };
    

    // Handle clicking the disabled generate button (fake button overlay)
    const handleDisabledGenerateClick = () => {
        if (!gridSize) {
            setShowWarning('Please select grid size before generating.');
        } else if (uploadedImages.length < requiredImages) {
            setShowWarning(`Please upload exactly ${requiredImages} T-shirts for the ${gridSize} grid before generating.`);
        }
    };

    const checkPaymentStatus = async (userId) => {
        try {
            // Reference the payments collection
            const paymentsCollectionRef = collection(db, `customers/${userId}/payments`);
            const querySnapshot = await getDocs(paymentsCollectionRef);
    
            if (!querySnapshot.empty) {
                // Assuming there's only one document in the collection
                const paymentDoc = querySnapshot.docs[0]; // Get the first document
                const paymentData = paymentDoc.data();
                return paymentData.status === "succeeded"; // Return true if the payment succeeded
            } else {
                console.error("No payment documents found.");
                return false;
            }
        } catch (error) {
            console.error("Error checking payment status:", error);
            return false;
        }
    };
 // Update handleAIClick to use the new format immediately
const handleAIClick = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
        console.error("User must be logged in to use this feature.");
        setAIError("Please log in to proceed.");
        return;
    }

    const userId = currentUser.uid;
    const userEmail = currentUser.email;
    
    // Debug logging
    console.log("Current user:", currentUser);
    console.log("Current user email:", userEmail);
    
    // Check if the user is whitelisted
    const whitelisted = isUserWhitelisted(userEmail);
    console.log("Is user whitelisted:", whitelisted);
    
    // Only bypass payment for whitelisted emails
    let paymentSucceeded = false;
    
    if (whitelisted) {
        console.log("User is whitelisted, bypassing payment check");
        paymentSucceeded = true;
    } else {
        console.log("User is not whitelisted, checking payment status");
        paymentSucceeded = await checkPaymentStatus(userId);
    }
    
    console.log("Payment succeeded:", paymentSucceeded);

    if (!paymentSucceeded) {
        // Payment not completed; show payment modal
        setIsPaymentModalOpen(true);
    } else {
        // Payment completed or whitelisted user; proceed with AI generation
        setIsPaymentCompleted(true); // Unlock feature
        setLoadingAI(true);
        setAIError(null);

        try {
            const response = await fetch('https://my-fastapi-app-289455571528.us-central1.run.app//ai-suggestions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ session_id: sessionId }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} ${errorText}`);
            }

            const rawData = await response.json();
            let suggestionsRaw = rawData.suggestions;

            if (typeof suggestionsRaw === 'string') {
                suggestionsRaw = suggestionsRaw
                    .replace(/```json/g, '')
                    .replace(/```/g, '')
                    .trim();
            }

            const parsedSuggestions = JSON.parse(suggestionsRaw);

            if (parsedSuggestions.layouts && Array.isArray(parsedSuggestions.layouts)) {
                setAIGeneratedLayouts(parsedSuggestions.layouts);
                await saveSessionData(); // Save AI layouts to Firestore or session
            } else {
                throw new Error('Invalid AI suggestions structure.');
            }
        } catch (error) {
            console.error('Failed to fetch AI suggestions:', error);
            setAIError(`Failed to fetch AI suggestions: ${error.message}`);
        } finally {
            setLoadingAI(false);
        }
    }
};

    // Add a dedicated function to check if a user is whitelisted
    const isUserWhitelisted = (email) => {
        if (!email) return false;
        
        // Debug logging
        console.log(`Checking if ${email} is in whitelist:`, WHITELISTED_EMAILS);
        
        // Exact match check (case insensitive)
        const normalizedEmail = email.toLowerCase().trim();
        const isWhitelisted = WHITELISTED_EMAILS.some(
            whitelistedEmail => whitelistedEmail.toLowerCase().trim() === normalizedEmail
        );
        
        console.log(`Email ${email} is ${isWhitelisted ? '' : 'NOT '}whitelisted`);
        return isWhitelisted;
    };

const getButtonText = () => {
    if (loadingAI) return "Generating...";
    if (isPremiumUser) return "Generate 5 AI-Enhanced Layouts";
    return "Generate 5 AI-Enhanced Layouts";
};


const PaymentModal = ({ onClose, onProceed }) => {
    const [isRedirecting, setIsRedirecting] = useState(false);

    const handleProceed = async () => {
        setIsRedirecting(true);
        await onProceed();
    };


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-lg font-semibold" style={{ fontFamily: "Playfair Display" }}>
                    Complete Your Payment
                </h2>
                <p className="mt-2 text-gray-600" style={{ fontFamily: "Playfair Display" }}>
                    To unlock the "Generate 5 AI-Enhanced Layouts" feature, please proceed with the payment. 
                    Once completed, you'll gain access to AI-powered layout generation tailored to your quilt.
                </p>
                <p className="mt-2 text-sm text-blue-600" style={{ fontFamily: "Playfair Display" }}>
                    Have a promotional code? You'll be able to apply it on the payment page.
                </p>
                <div className="mt-4 flex justify-end space-x-4">
                    <button
                        className="px-4 py-2 bg-gray-200 rounded-md text-gray-700 hover:bg-gray-300"
                        onClick={onClose}
                        disabled={isRedirecting}
                        style={{ fontFamily: "Playfair Display" }}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center"
                        onClick={handleProceed}
                        disabled={isRedirecting}
                        style={{ fontFamily: "Playfair Display" }}
                    >
                        {isRedirecting ? (
                            <>
                                <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Redirecting...
                            </>
                        ) : (
                            'Proceed to Payment'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

useEffect(() => {
    if (!gridSize) return;
    getPriceForGridSize(gridSize);
  }, [gridSize, userCountry]);
  
  



const handlePaymentProceed = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
        console.error("User must be logged in to proceed with payment.");
        setAIError("Please log in to proceed.");
        return;
    }

    const priceId = priceMapping[gridSize];
    if (!priceId) {
        console.error("No price found for this grid size.");
        setAIError("Pricing information not available.");
        return;
    }

    try {
        // Show loading state
        setIsPaymentModalOpen(true);
        
        // Create the checkout session
        const customerRef = doc(db, "customers", currentUser.uid);
        const checkoutSessionRef = collection(customerRef, "checkout_sessions");
        
        const docRef = await addDoc(checkoutSessionRef, {
            price: priceId,
            success_url: window.location.href,
            cancel_url: window.location.href,
            mode: "payment",
            allow_promotion_codes: true,
            metadata: {
                gridSize: gridSize,
                userId: currentUser.uid
            }
        });

        // Set up the listener with proper cleanup
        let unsubscribed = false;
        const unsubscribe = onSnapshot(docRef, (snap) => {
            const { error, url } = snap.data() || {};
            
            if (error) {
                setAIError(`An error occurred: ${error.message}`);
                setIsPaymentModalOpen(false);
                unsubscribe();
                return;
            }

            if (!unsubscribed && url) {
                window.location.assign(url);
                unsubscribe();
            }
        });

        // Set a timeout that will only trigger if URL redirect hasn't happened
        setTimeout(() => {
            if (!unsubscribed) {
                unsubscribed = true;
                unsubscribe();
                setIsPaymentModalOpen(false);
                setAIError("Payment session creation timed out. Please try again.");
            }
        }, 30000); // 30 seconds timeout

    } catch (error) {
        console.error("Error creating checkout session:", error);
        setAIError(`Payment initialization failed: ${error.message}`);
        setIsPaymentModalOpen(false);
    }
};

useEffect(() => {
    const fetchUserCountry = async () => {
        try {
            const response = await fetch('https://ipapi.co/json/');
            const data = await response.json();

            if (data && data.country_code) {
                console.log(`User country: ${data.country_name} (${data.country_code})`);
                setUserCountry(data.country_code); // Store the country code (e.g., 'IN' for India)
            } else {
                console.warn('Country data not found. Defaulting to USD.');
                setUserCountry('US'); // Fallback to 'US'
            }
        } catch (error) {
            console.error('Error fetching country:', error);
            setUserCountry('US'); // Fallback to 'US' on error
        }
    };

    fetchUserCountry();
}, []);

// Function to calculate price for a given grid size
const getPriceForGridSize = (size) => {
    const pricingTiersUSD = {
        '4x4': 4,   // 4x4 grid = $4
        '4x5': 5,   // 4x5 grid = $5
        '4x6': 6,   // 4x6 grid = $6
        '4x7': 7,   // 4x7 grid = $7
        '5x5': 6,   // 5x5 grid = $6
        '5x6': 7,   // 5x6 grid = $7
        '5x7': 8,   // 5x7 grid = $8
        '6x6': 8,   // 6x6 grid = $8
        '6x7': 9,   // 6x7 grid = $9
        '7x7': 10,  // 7x7 grid = $10
        '7x8': 10,  // 7x8 grid = $10
        '8x8': 10,  // 8x8 grid = $10
    };

    const pricingTiersINR = {
        '4x4': 343,   // 4x4 grid = ₹500
        '4x5': 428,   // 4x5 grid = ₹450
        '4x6': 514,   // 4x6 grid = ₹550
        '4x7': 599,   // 4x7 grid = ₹650
        '5x5': 514,   // 5x5 grid = ₹600
        '5x6': 599,   // 5x6 grid = ₹700
        '5x7': 685,   // 5x7 grid = ₹800
        '6x6': 685,   // 6x6 grid = ₹850
        '6x7': 771,   // 6x7 grid = ₹950
        '7x7': 856,  // 7x7 grid = ₹1000
        '7x8': 856,  // 7x8 grid = ₹1050
        '8x8': 856,  // 8x8 grid = ₹1100
    };

    if (userCountry === 'IN') {
        const priceInINR = pricingTiersINR[size] || 500; // Default to ₹500 if size not found
        setPriceInRupees(priceInINR); // Update INR price in state
        return `₹${priceInINR}`;
    }

    const priceInUSD = pricingTiersUSD[size] || 5; // Default to $5 if size not found
    return `$${priceInUSD}`;
};





    return (
        <QuiltBuilderWithLayout handleReset={openResetModal} isGenerated={quiltImageUrl}>
            <HotjarIntegration />
            <div className="flex flex-col w-full min-h-screen bg-[#F6E8E0]">
    
                {/* Main Content Area */}
                <div className="flex flex-col items-center w-full overflow-y-auto p-8">
                    {/* Top bar containing centered title and reset button */}
                    <div className="flex flex-col items-center w-full overflow-auto p-8 relative">
                    <div
    className="text-xl sm:text-2xl font-semibold text-gray-800 text-center sm:text-left break-words"
    style={{ fontFamily: "Playfair Display" }}
>
    Craft your memory quilt.
</div>


                    </div>
    
                    {/* Reset Confirmation Modal */}
                    {isResetModalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-lg font-semibold" style={{ fontFamily: "Playfair Display" }}>Start New Project?</h2>
                                <p className="mt-2 text-gray-600" style={{ fontFamily: "Playfair Display" }}>
                                    Your current design will be lost. Please make sure to download your design before starting a new project.
                                </p>
                                <div className="mt-4 flex justify-end space-x-4">
                                    <button
                                        className="px-4 py-2 bg-gray-200 rounded-md text-gray-700 hover:bg-gray-300"
                                        onClick={closeResetModal} style={{ fontFamily: "Playfair Display" }}
                                    >
                                        Cancel
                                    </button>
                                    <button
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center justify-center"
        onClick={() =>
            confirmReset(
                sessionId,
                storage,
                db,
                setUploadedImages,
                setGridSize,
                setGenerated,
                setRequiredImages,
                setShowWarning,
                setQuiltImageUrl,
                setPreviousLayoutType,
                setGenerateDisabled,
                setIsEditUnlocked,
                setQuiltGrid,
                setSessionId,
                setFeedbackSubmitted,
                setRating,
                setIsResetLoading, // Ensure this is correctly passed
                setAIGeneratedLayouts,
                closeResetModal
            )
        }
        disabled={isResetLoading} // Disable button when loading
    >
        {isResetLoading ? (
            <>
                <span className="spinner"></span> {/* Spinner element */}
                Loading...
            </>
        ) : (
            "Continue"
        )}
    </button>
                                </div>
                            </div>
                        </div>
                    )}
    
                    {/* Grid Size Section */}
<div className="flex justify-center w-full mb-8 ">
    <div className="bg-white w-full sm:w-[400px] p-4 rounded-lg shadow-lg bg-zinc-300">
        <div className="bg-gray-100 p-4 rounded-lg  ">
            <div className="flex flex-col items-center ">
                <label className="text-sm font-bold mb-1" style={{ fontFamily: "Playfair Display" }}>Grid Size</label>
                <select
                    value={gridSize}
                    onChange={handleGridSizeChange}
                    className="border rounded-lg p-2 text-gray-800 bg-white shadow-md"
                    disabled={generated}
                >
                    <option value="" disabled>Select</option>
                    <option value="4x4">4x4 (16 T-shirts)</option>
                    <option value="4x5">4x5 (20 T-shirts)</option>
                    <option value="4x6">4x6 (24 T-shirts)</option>
                    <option value="4x7">4x7 (28 T-shirts)</option>
                    <option value="5x5">5x5 (25 T-shirts)</option>
                    <option value="5x6">5x6 (30 T-shirts)</option>
                    <option value="5x7">5x7 (35 T-shirts)</option>
                    <option value="6x6">6x6 (36 T-shirts)</option>
                    <option value="6x7">6x7 (42 T-shirts)</option>
                    <option value="7x7">7x7 (49 T-shirts)</option>
                    <option value="7x8">7x8 (56 T-shirts)</option>
                    <option value="8x8">8x8 (64 T-shirts)</option>
                </select>
            </div>
        </div>
    </div>
</div>

    
                    {/* Warning message */}
                    {showWarning && (
                        <div className="mb-4 text-red-600">
                            {showWarning}
                        </div>
                    )}
    
                    {/* Notify user to upload images */}
                    {!showWarning && gridSize && uploadedImages.length < requiredImages && (
                        <div className="mb-4 text-blue-600" style={{ fontFamily: "Playfair Display" }}>
                            Please upload {requiredImages - uploadedImages.length} more T-shirts for the {gridSize} grid.
                        </div>
                    )}
    
                    {/* Image Upload Container and Generate Button */}
                    <div className="flex flex-col items-center bg-white border border-gray-300 p-6 rounded-lg shadow-lg w-full sm:w-[700px]">
                        {/* Upload Icon and file input */}
{!generated && !loading && (
    <label className="flex flex-col items-center justify-center cursor-pointer ">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 10h4M3 6h4m4 0h6M3 14h4m4 0h6m0 0v5a2 2 0 01-2 2H8a2 2 0 01-2-2v-5m0-4h4m4 0h4"
            />
        </svg>
        <span className="mt-2 text-sm text-gray-600 " style={{ fontFamily: "Playfair Display" }}>
            Upload T-shirts ({uploadedImages.length}/{requiredImages})
        </span>
        <input
            type="file"
            multiple
            ref={fileInputRef}
            onClick={handleUploadClick}
            onChange={handleImageUpload}
            className="hidden"
        />
    </label>
)}

    
                        <div className="w-full border-t border-gray-300 my-4"></div>
    
                        <div className="mt-4 w-full h-32 overflow-y-auto grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4">
                            {uploadedImages.map((image, index) => (
                                <div key={index} className="relative w-24 h-24 p-1">
                                    <img
                                        src={image.url}
                                        alt={`Uploaded ${index}`}
                                        className="w-full h-full object-cover rounded-lg"
                                    />
                                    {!image.processed && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50" style={{ fontFamily: "Playfair Display" }}>
                                            <span className="text-blue-500">Processing...</span>
                                        </div>
                                    )}
                                    {!generated && !loading && (
                                        <button
                                            className="absolute top-1 right-1 bg-red-500 text-white text-xs w-6 h-6 flex items-center justify-center rounded-full"
                                            onClick={() => handleDeleteImage(index)}
                                        >
                                            X
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>
    
                {/* Generate Button next to image upload container */}
{!generated && (
    <div className="relative flex justify-center mt-4">
        <button
            onClick={handleGenerateClick}
            className={`bg-blue-500 text-white px-6 py-3 rounded-lg ${
                isProcessingImages || generateDisabled || loading || uploadedImages.length < requiredImages || !gridSize || uploadedImages.some(img => !img.processed)
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-blue-600'
            }`}
            disabled={
                isProcessingImages || generateDisabled || loading || uploadedImages.length < requiredImages || !gridSize || uploadedImages.some(img => !img.processed)
            }
        >
            {isProcessingImages ? 'Processing...' : loading ? 'Generating Design...' : 'Generate Design'}
        </button>

        {processingError && (
            <div className="text-red-600 mt-2">{processingError} Please reset the project and try again. </div>
        )}

        {/* Overlay only if the button is disabled */}
        {(uploadedImages.length < requiredImages || !gridSize) && (
            <div
                className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={handleDisabledGenerateClick}
                style={{ zIndex: 10, backgroundColor: 'rgba(0, 0, 0, 0)' }} // Transparent overlay
            ></div>
        )}
    </div>
)}

                    </div>
    
                    <div ref={quiltDisplayRef} className="flex flex-col items-center justify-center w-full mt-8">
                        
    {/* Main Quilt Section */}
    <div className="bg-gray-100 p-4 rounded-lg shadow-lg w-full sm:w-[500px] md:w-[600px] flex flex-col items-center border-4 border-zinc-300">
    {quiltImageUrl && (
        <div className="text-xl font-semibold text-gray-700 mb-4" style={{ fontFamily: "Playfair Display" }}>
            Basic {gridSize} arrangement
        </div>
    )}
        {loading ? (
            <img src={loadingGif} alt="Loading..." className="w-16 h-16" />
        ) : quiltImageUrl ? (
            <>
                {/* Display Quilt */}
                {isEditing ? (
                    <QuiltEditor
                        quiltGrid={quiltGrid}
                        gridSize={gridSize}
                        onSave={handleSaveChanges}
                        onCancel={handleCancelEdit}
                    />
                ) : (
                    <>
                        <div className="quilt-container max-w-full overflow-x-auto flex justify-center items-center">
                            <canvas ref={canvasRef} className="max-w-full max-h-full"></canvas>
                        </div>
                       <div 
  className="
    flex 
    flex-row 
    items-center 
    gap-4 
    mt-4 
    justify-center    /* Center horizontally on small screens */
    overflow-x-auto   /* Allow horizontal scroll if needed */
    whitespace-nowrap /* Prevent wrapping */
  "
>
  {/* Action Icons */}
  <button 
    onClick={handleEditClick} 
    className="flex items-center justify-center gap-2 text-blue-500 hover:text-blue-600 cursor-pointer"
  >
    <FontAwesomeIcon icon={faEdit} className="text-lg" />
    <span>Edit</span>
  </button>

  <button 
    onClick={() => handleDownloadClick(canvasRef, gridSize, currentUser, storage)} 
    className="flex items-center justify-center gap-2 text-green-500 hover:text-green-600 cursor-pointer"
  >
    <FontAwesomeIcon icon={faDownload} className="text-lg" />
    <span>Download</span>
  </button>

  <button 
    onClick={() => handleShareClick(canvasRef, setShowModal, setShareLink, currentUser, storage, db)} 
    className="flex items-center justify-center gap-2 text-gray-500 hover:text-gray-600 cursor-pointer"
  >
    <FontAwesomeIcon icon={faShareAlt} className="text-lg" />
    <span>Share</span>
  </button>

  {showModal && <ShareModal shareLink={shareLink} setShowModal={setShowModal} />}
</div>

                    </>
                )}
            </>
        ) : (
            <span className="text-gray-500">
                ✨ Your personalized quilt design will magically appear here! ✨
            </span>
        )}

{quiltImageUrl && aiGeneratedLayouts.length === 0 && (
    <div className="flex flex-col items-center mt-4 sm:mt-8 w-full max-w-lg px-2 sm:px-0">
        <button
            onClick={handleAIClick}
            className={`w-[240px] sm:w-auto sm:min-w-[280px] px-3 sm:px-6 py-2 sm:py-4 rounded-lg sm:rounded-xl text-white flex flex-col items-center
                ${loadingAI ? 'opacity-50 cursor-not-allowed' : ''}
                bg-gradient-to-r from-violet-500 to-pink-500 hover:from-violet-600 hover:to-pink-600
                transition-all duration-200 ease-in-out shadow-lg`}
            disabled={loadingAI}
        >
            <div className="flex items-center justify-between w-full gap-1.5 sm:gap-3">
                <div className="flex items-center gap-1.5 sm:gap-3">
                    {loadingAI ? (
                        <svg 
                            className="animate-spin w-4 h-4 sm:w-6 h-6" 
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                        >
                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" className="opacity-25"/>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                        </svg>
                    ) : (
                        <svg
                            className="w-4 h-4 sm:w-6 h-6"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z"/>
                        </svg>
                    )}
                    <div className="text-sm sm:text-lg font-semibold">
                        {getButtonText()}
                    </div>
                </div>
                {!isPremiumUser && (
                    <div className="text-base sm:text-xl font-bold">
                        {userCountry === 'IN' && priceInRupees !== null
                            ? `₹${priceInRupees}`
                            : `${getPriceForGridSize(gridSize)}`} 
                    </div>
                )}
            </div>
            {!isPremiumUser && (
                <div className="mt-0.5 sm:mt-2 text-[10px] sm:text-sm opacity-80 text-center">
                    Unlock premium {gridSize} arrangements with AI magic
                </div>
            )}
        </button>

        {aiError && (
            <div className="text-red-500 text-xs sm:text-sm mt-2">
                {aiError}
            </div>
        )}

        {isPaymentModalOpen && (
            <PaymentModal
                onClose={() => setIsPaymentModalOpen(false)}
                onProceed={handlePaymentProceed}
            />
        )}
    </div>
)}


    </div>

    {/* Separator */}
    <div className="w-full border-t border-gray-300 my-8"></div>

    {/* AI-Generated Layouts Section */}
    {aiGeneratedLayouts.length > 0 && (
        <div className="w-full">
    <AILayouts
        layouts={aiGeneratedLayouts}
        quiltGrid={quiltGrid}
        gridSize={gridSize} // Pass gridSize here
        onEdit={handleEditClick}
        onDownload={handleDownloadClick}
        onShare={handleShareClick}
        logoSrc={logoSrc} // Pass logoSrc here
        currentUser={currentUser}
        storage={storage} 
    />
        </div>
    )}



    <FeedbackSection
    quiltImageUrl={quiltImageUrl}
    currentUser={currentUser}
    setFeedbackSubmitted={setFeedbackSubmitted}
    setShowThankYouMessage={setShowThankYouMessage}
    feedbackSubmitted={feedbackSubmitted}
    showThankYouMessage={showThankYouMessage}
/>

{quiltImageUrl && <QuilterOptions />}


                    </div>
                </div>
            </div>
            <TawkChat />
        </QuiltBuilderWithLayout>
    );
}    
export default QuiltBuilder;